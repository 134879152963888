import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import { Link } from "gatsby"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

import Guidelines from "../assets/pdf/1Oxygen-Home-Loans-Lending-Guidelines-Final.pdf"
// import ComRateCard from "../assets/pdf/May/Oxygen-Complete-Rate-Card-V2.0-May-2023.pdf"
// import CompleteFactSheet from "../assets/pdf/November/Oxygen-Complete-Fact-Sheet-2-November-2022.pdf"
import HomeLoans from "../assets/pdf/4Oxygen-Home-Loans-Broker-Info-Final.pdf"
import CustomerBrochure from "../assets/pdf/5Oxygen-Customer-Brochure-Final.pdf"
import PostCodes from "../assets/pdf/6Oxygen-Post-Codes-Final.pdf"
import PConsent from "../assets/pdf/9Privacy-Consent-OLS-01Sep2022.pdf"

import EasyRefinance from "../assets/pdf/November2023/Oxygen-SMSF-Easy-Refinance-Docs.pdf"
import SMSFFactSheet from "../assets/pdf/November2023/Oxygen-SMSF-Fact-Sheet-20-November-2023.pdf"
import SMSFFixedRate from "../assets/pdf/November2023/Oxygen-SMSF-Fixed-Rate-Card-20-November-2023.pdf"
import SMSFRate from "../assets/pdf/November2023/Oxygen-SMSF-Variable-Rate-Card-20-November-2023.pdf"

import DLPurchase from "../assets/pdf/November2023/Oxygen-SMSF-Loan-Purchase-Doc-List-31-October-23.pdf"
import DLRefinance from "../assets/pdf/November2023/Oxygen-SMSF-Loan-Refinance-Doc-List-31-October-23.pdf"
import DLBrokerNOxygen from "../assets/pdf/August/Document-list-Broker-&-Oxygen.pdf"
import SMSFBrokerTraining from "../assets/pdf/November2023/SMSF-product-slides-2023-Nov23.pdf"

// import LoanSubmission from "../assets/pdf/January/Loan.docx"

const Terms = () => {
  return (
    <Layout>
      <SEO title="Broker Information | Oxygen - Broker Information Only" />
      <Navbar />
      <PageBanner
        pageTitle="Broker Information Only"
        subHeading=""
        pageSubTitle=""
      />
      <div className="container mb-5 page-title-area pt-0 pb-0">
        <div className="page-title-content">
          <h2>SMSF Lending</h2>
        </div>
      </div>

      {/* Rate Cards */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Rate Cards</h3>
                <ul>
                  <li>
                    <a
                      href={SMSFRate}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen SMSF Variable Rate Card (PDF)
                      {/* Oxygen SMSF Rate Card Final Sept 22 */}
                    </a>
                  </li>
                  <li>
                    <a
                      href={SMSFFixedRate}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen SMSF Fixed Rate Card (PDF)
                      {/* Oxygen SMSF Rate Card Final Sept 22 */}
                    </a>
                  </li>
                  <li>
                    <a
                      href={SMSFFactSheet}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen SMSF Fact Sheet (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.GATSBY_S3_URL}/web-site/broker-information-page/Oxygen_Post_Codes_Aug_2024.pdf`}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen Post Codes (PDF)
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Documents */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Documents</h3>
                <ul>
                  <li>
                    <a
                      href={`${process.env.GATSBY_S3_URL}/web-site/broker-information-page/Oxygen_Loan_Aplication_Requirements_Fast_Refinance_(11March24).pdf`}
                      className="underlined-link link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Document list - Easy Refinance (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.GATSBY_S3_URL}/web-site/broker-information-page/Oxygen_Loan_Application_Requirements_Final+(11March24)_Purchase.pdf`}
                      className="underlined-link link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Document list Purchase (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.GATSBY_S3_URL}/web-site/broker-information-page/Oxygen_Loan_Application_Requirements_Final_(11March24)_Refinance.pdf`}
                      className="underlined-link link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Document list Refinance (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={DLBrokerNOxygen}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Document list Broker & Oxygen (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={PConsent}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen Customer Privacy Consent (PDF)
                    </a>
                  </li>
                  <li>
                    <Link
                      to="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/broker-information-page/Loan+Submission+Summary_SMSF_Blank_Broker+to+Complete.docx"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Loan Summary Template (DOC)
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Serviceability */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Serviceability</h3>
                <ul>
                  <li>
                    <Link
                      to="https://highlighter.ytml.com.au/Public/Start?guid=8f02e6e9-c2e0-4d53-8cdb-597c6876fb4b"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Purchase Serviceability Calculator
                    </Link>
                    {/* <a href={LMICAL} className="underlined-link link" target="_blank">LMI Calculator</a> */}
                  </li>
                  <li>
                    <Link
                      to="https://highlighter.ytml.com.au/Public/Start?guid=3ab69591-140a-4b66-ad4a-14ddbb2cbcf2"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Refinance Serviceability Calculator
                    </Link>
                  </li>
                  <li>
                    <a
                      href={`${process.env.GATSBY_OLAP_BASE_URL}/SMSF/Quote`}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen SMSF Easy Calc
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Scenarios */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Scenarios</h3>
                <ul>
                  <li>
                    {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                      <Link
                        to="https://apps.staging.oxygenloans.com.au/Application/BrokerInformation"
                        className="underlined-link link"
                        target="_blank"
                      >
                        Find Rates for a Scenario
                      </Link>
                    ) : (
                      <Link
                        to="https://apps.oxygenloans.com.au/Application/BrokerInformation"
                        className="underlined-link link"
                        target="_blank"
                      >
                        Find Rates for a Scenario
                      </Link>
                    )}
                  </li>
                  <li>
                    <Link
                      to="https://forms.gle/XkxFcLzvW8Nuhw696"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Order Valuation Form
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Information and Guidelines */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Information and Guidelines</h3>
                <ul>
                  <li>
                    <a
                      href={Guidelines}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen Home Loans Lending Guidelines (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={HomeLoans}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen Home Loans Broker Info (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={CustomerBrochure}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen Home Loans Customer Info (PDF)
                    </a>
                  </li>
                  <li>
                    <a
                      href={SMSFBrokerTraining}
                      className="underlined-link link"
                      target="_blank"
                    >
                      Oxygen SMSF Broker Training
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* LoanApp */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">LoanApp</h3>
                <ul>
                  <li>
                    <Link
                      to="https://app.loanapp.com.au/oxygen"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Loan App Website
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Customers */}
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="terms-of-service-content pt-3">
                <h3 className="pt-lg-3 pt-md-0">Customers</h3>
                <ul>
                  <li>
                    <Link
                      to="https://online.originmms.com.au/ib/oxygen"
                      className="underlined-link link"
                      target="_blank"
                    >
                      Online banking
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}
export default Terms
